<template>
  <div class="content">
    <PageHeader :title="$tc('app.invoice', 2)" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <card
            :title="$tc('app.invoice', 2)"
            actions
            block
            :loading="loading"
            @reload="fetchInvoices"
          >
            <template #body>
              <data-table
                :data="invoices"
                :pages="pages"
                :columns="[
                  {
                    key: 'total',
                    filters: ['currency'],
                  },
                  {
                    key: 'status',
                    class: 'badge badge-primary',
                  },
                  {
                    key: 'gw_id',
                    label: 'id',
                  },
                  {
                    key: 'due_date',
                    filters: ['date|DD/MM/YYYY'],
                  },
                  {
                    key: 'created_at',
                    filters: ['date|DD/MM/YYYY H:m:s'],
                  },
                  {
                    key: 'account.name',
                    label: 'customer',
                  },
                ]"
                @change-page="fetchInvoices"
              >
                <template #actions="item">
                  <a
                    target="_blank"
                    :href="`https://faturas.iugu.com/${converToUUID(
                      item.gw_id,
                    )}`"
                  >
                    <i class="fas fa-search"></i>
                  </a>
                </template>
              </data-table>
            </template>
          </card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';

export default {
  name: 'AdminInvoices',
  components: {
    PageHeader,
    Card,
    DataTable,
  },
  data() {
    return {
      loading: true,
      q: '',
      page: 1,
      pages: 1,
      invoices: [],
    };
  },
  created() {
    this.fetchInvoices();
  },
  methods: {
    fetchInvoices(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchAdminInvoices', { page, q: this.q })
        .then((response) => {
          this.pages = response.last_page;
          this.invoices = response.data;
          this.loading = false;
        });
    },
    insertString(str, index, value) {
      return str.substr(0, index) + value + str.substr(index);
    },
    converToUUID(str) {
      if (!str) return '';
      str = this.insertString(str, 8, '-');
      str = this.insertString(str, 13, '-');
      str = this.insertString(str, 18, '-');
      str = this.insertString(str, 23, '-');

      return str.toLowerCase();
    },
  },
};
</script>
<style scoped lang="scss">
.block-el {
  min-height: 300px;
}
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.not-found a {
  color: var(--gray);
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
