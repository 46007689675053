var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('PageHeader',{attrs:{"title":_vm.$tc('app.invoice', 2)}}),_c('section',{staticClass:"page-content container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"title":_vm.$tc('app.invoice', 2),"actions":"","block":"","loading":_vm.loading},on:{"reload":_vm.fetchInvoices},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.invoices,"pages":_vm.pages,"columns":[
                {
                  key: 'total',
                  filters: ['currency'],
                },
                {
                  key: 'status',
                  class: 'badge badge-primary',
                },
                {
                  key: 'gw_id',
                  label: 'id',
                },
                {
                  key: 'due_date',
                  filters: ['date|DD/MM/YYYY'],
                },
                {
                  key: 'created_at',
                  filters: ['date|DD/MM/YYYY H:m:s'],
                },
                {
                  key: 'account.name',
                  label: 'customer',
                } ]},on:{"change-page":_vm.fetchInvoices},scopedSlots:_vm._u([{key:"actions",fn:function(item){return [_c('a',{attrs:{"target":"_blank","href":("https://faturas.iugu.com/" + (_vm.converToUUID(
                    item.gw_id
                  )))}},[_c('i',{staticClass:"fas fa-search"})])]}}])})]},proxy:true}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }